import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import "./index.scss";
import App from "./components/app";
import PerfectScrollbar from "react-perfect-scrollbar";
import { ToastContainer } from "react-toastify";
import { store } from "./store";

// Components
import Dashboard from "./components/dashboard";
// Category
import Productcategory from "./components/category";
import AddCategory from "./components/category/create-category";

// brand

//Product Digital

//Sales
import Orders from "./components/sales/orders";
import Transactions_sales from "./components/sales/transactions-sales";
//Coupons
import ListCoupons from "./components/coupons/list-coupons";
import Create_coupons from "./components/coupons/create-coupons";

//Pages
import ListPages from "./components/pages/list-page";
import Create_page from "./components/pages/create-page";
import Media from "./components/media/media";
import List_menu from "./components/menus/list-menu";
import Create_menu from "./components/menus/create-menu";
import List_user from "./components/users/list-user";
import Create_user from "./components/users/create-user";
import List_vendors from "./components/vendors/list-vendors";
import Create_vendors from "./components/vendors/create.vendors";
import Translations from "./components/localization/translations";
import Rates from "./components/localization/rates";
import Taxes from "./components/localization/taxes";
import Profile from "./components/settings/profile";
import Reports from "./components/reports/report";
import Invoice from "./components/invoice";
import Datatable from "./components/common/datatable";
import Login from "./components/auth/login";
import AdminRoute from "./components/PrivateRoutes/adminRoute";
import AddBrand from "./components/brand/create-brand";
import Productbrand from "./components/brand";
import AddBox from "./components/box/create-box";
import Productbox from "./components/box";

import List_product from "./components/products/list-product";
import Create_product from "./components/products/create.product";
import Productrarity from "./components/products/rarityLevel";
import AddRaritylavel from "./components/products/rarityLevel/createRarity";

import Product_detail from "./components/products/product-detail";
import UserProfileDetails from "./components/users/user-profile-details";
import BotPlayList from "./components/botPlay/index";
import PlayBox from "./components/botPlay/create-game";
import FundsManagement from "./components/fundManagement/funds";
import InventoryManagement from "./components/inventoryManagement";
import DepositeManagement from "./components/deposite/index";
import Announcement from "./components/announcement/announcement";
import AddAnnouncement from "./components/announcement/create-announcement";
import BattleList from "./components/battles/battle";
import AddBattle from "./components/battles/create-battle";
import TeamManagement from "./components/teamManagement/TeamManagement";
import AddTeam from "./components/teamManagement/addTeam";
import UpdateTeam from "./components/teamManagement/updateTeam";
import ActivityLogList from "./components/activityLog/index";
import OrderDetail from "./components/orderpage/orderDetail";
import InvoiceDetail from "./components/orderpage/invoice";
import Bill from "./components/deposite/bill";
import { adminInstance } from "./config/axios";
import ShippingManagement from "./components/shippingManagement";
import FaqManagement from "./components/FaqManagement/category";
import AddCategoryfaq from "./components/FaqManagement/category/faq-category";
//import AddFaqForm from "./components/FaqManagement/faq/addFaqForm"
import FaqManagementData from "./components/FaqManagement/faq";
import Addfaq from "./components/FaqManagement/faq/faqForm";
import AffiliateLevelManagement from "./components/affiliate/levelManagement";
import AddAffiliateLevel from "./components/affiliate/addAffiliateLevel";
import AffiliateUserManagement from "./components/affiliate/affiliateUsers";
import AffiliateStats from "./components/affiliate/affiliatestats";

// Allowed Country component
import AllowedCountry from "./components/AllowedCountry/allowedCountry";
import RainDrop from "./components/RainDrop";
import RackBackSettings from "./components/RackBackSettings";

const Root = () => {
  const [newRole, setNewRole] = useState();
  const getAdminLoginData = async () => {
    try {
      const result = await adminInstance().get("api/admin/getAdminDetails");
      const { adminDetails } = result.data || {};
      const { role } = adminDetails || [];
      if (role?.length > 0) {
        setNewRole(role);
      }
    } catch (err) {
      console.log("Error is here-->", err);
    }
  };
  useEffect(() => {
    getAdminLoginData();
  }, []);

  return (
    <Provider store={store}>
      <BrowserRouter basename={"/"}>
        <PerfectScrollbar>
          <Switch>
            <Route exact path="/" component={Login} />
            <App newRole={newRole}>
              {/* {newRole &&
								newRole?.some((r) =>
									[
										'suUsers',
										'readDashboard',
										'writeDashboard',
										'delDashboard',
										'superAdmin',
									].includes(r)
								) && */}{" "}
              <Route path="/dashboard" component={Dashboard} />
              {/* } */}
              {newRole &&
              newRole?.some((r) =>
                [
                  "suUsers",
                  "superAdmin",
                  "readBox",
                  "writeBox",
                  "delBox",
                ].includes(r)
              ) ? (
                <AdminRoute exact path="/create-box" component={AddBox} />
              ) : (
                <AdminRoute exact path="/create-box" component={Dashboard} />
              )}
              {newRole &&
              newRole?.some((r) =>
                [
                  "suUsers",
                  "superAdmin",
                  "readBox",
                  "writeBox",
                  "delBox",
                ].includes(r)
              ) ? (
                <AdminRoute exact path="/box-list" component={Productbox} />
              ) : (
                <AdminRoute exact path="/box-list" component={Dashboard} />
              )}
              {newRole &&
              newRole?.some((r) =>
                [
                  "suUsers",
                  "superAdmin",
                  "readBrand",
                  "writeBrand",
                  "delBrand",
                ].includes(r)
              ) ? (
                <AdminRoute exact path="/create-brand" component={AddBrand} />
              ) : (
                <AdminRoute exact path="/create-brand" component={Dashboard} />
              )}
              {newRole &&
              newRole?.some((r) =>
                [
                  "suUsers",
                  "superAdmin",
                  "readBrand",
                  "writeBrand",
                  "delBrand",
                ].includes(r)
              ) ? (
                <AdminRoute exact path="/brand-list" component={Productbrand} />
              ) : (
                <AdminRoute exact path="/brand-list" component={Dashboard} />
              )}
              {newRole &&
              newRole?.some((r) =>
                [
                  "suUsers",
                  "superAdmin",
                  "readAnnouncement",
                  "writeAnnouncement",
                  "delAnnouncement",
                ].includes(r)
              ) ? (
                <AdminRoute
                  exact
                  path="/announcements"
                  component={Announcement}
                />
              ) : (
                <AdminRoute exact path="/announcements" component={Dashboard} />
              )}
              {newRole &&
              newRole?.some((r) =>
                [
                  "suUsers",
                  "superAdmin",
                  "readAnnouncement",
                  "writeAnnouncement",
                  "delAnnouncement",
                ].includes(r)
              ) ? (
                <AdminRoute
                  exact
                  path="/create-announcement"
                  component={AddAnnouncement}
                />
              ) : (
                <AdminRoute
                  exact
                  path="/create-announcement"
                  component={Dashboard}
                />
              )}
              {newRole &&
              newRole?.some((r) =>
                [
                  "suUsers",
                  "superAdmin",
                  "readBattle",
                  "writeBattle",
                  "delBattle",
                ].includes(r)
              ) ? (
                <AdminRoute exact path="/battle-list" component={BattleList} />
              ) : (
                <AdminRoute exact path="/battle-list" component={Dashboard} />
              )}
              {newRole &&
              newRole?.some((r) =>
                [
                  "suUsers",
                  "superAdmin",
                  "readBattle",
                  "writeBattle",
                  "delBattle",
                ].includes(r)
              ) ? (
                <AdminRoute exact path="/create-battle" component={AddBattle} />
              ) : (
                <AdminRoute exact path="/create-battle" component={Dashboard} />
              )}
              {newRole &&
              newRole?.some((r) =>
                [
                  "suUsers",
                  "superAdmin",
                  "readCategory",
                  "writeCategory",
                  "delCategory",
                ].includes(r)
              ) ? (
                <AdminRoute
                  exact
                  path="/category-list"
                  component={Productcategory}
                />
              ) : (
                <AdminRoute exact path="/category-list" component={Dashboard} />
              )}
              {newRole &&
              newRole?.some((r) =>
                [
                  "suUsers",
                  "superAdmin",
                  "readCategory",
                  "writeCategory",
                  "delCategory",
                ].includes(r)
              ) ? (
                <AdminRoute
                  exact
                  path="/create-category"
                  component={AddCategory}
                />
              ) : (
                <AdminRoute
                  exact
                  path="/create-category"
                  component={Dashboard}
                />
              )}
              {newRole &&
              newRole?.some((r) =>
                [
                  "suUsers",
                  "superAdmin",
                  "readProduct",
                  "writeProduct",
                  "delProduct",
                ].includes(r)
              ) ? (
                <AdminRoute
                  exact
                  path="/products/view-product"
                  component={Product_detail}
                />
              ) : (
                <AdminRoute
                  exact
                  path="/products/view-product"
                  component={Dashboard}
                />
              )}
              <AdminRoute exact path="/sales/orders" component={Orders} />
              <AdminRoute
                exact
                path="/sales/transactions"
                component={Transactions_sales}
              />
              <AdminRoute
                exact
                path="/coupons/list-coupons"
                component={ListCoupons}
              />
              <AdminRoute
                exact
                path="/coupons/create-coupons"
                component={Create_coupons}
              />
              <AdminRoute exact path="/pages/list-page" component={ListPages} />
              <AdminRoute
                exact
                path="/pages/create-page"
                component={Create_page}
              />
              <AdminRoute exact path="/media" component={Media} />
              <AdminRoute exact path="/menus/list-menu" component={List_menu} />
              <AdminRoute exact path="/order-details" component={OrderDetail} />
              <AdminRoute
                exact
                path="/invoice-details"
                component={InvoiceDetail}
              />
              <AdminRoute
                exact
                path="/menus/create-menu"
                component={Create_menu}
              />
              {newRole &&
              newRole?.some((r) =>
                [
                  "suUsers",
                  "superAdmin",
                  "readUsers",
                  "writeUsers",
                  "delUsers",
                ].includes(r)
              ) ? (
                <AdminRoute
                  exact
                  path="/users/list-user"
                  component={List_user}
                />
              ) : (
                <AdminRoute
                  exact
                  path="/users/list-user"
                  component={Dashboard}
                />
              )}
              {newRole &&
              newRole?.some((r) =>
                [
                  "suUsers",
                  "superAdmin",
                  "readProduct",
                  "writeProduct",
                  "delProduct",
                ].includes(r)
              ) ? (
                <Route
                  exact
                  path="/users/create-user"
                  component={Create_user}
                />
              ) : (
                <Route exact path="/users/create-user" component={Dashboard} />
              )}
              <AdminRoute
                exact
                path="/vendors/list_vendors"
                component={List_vendors}
              />
              <AdminRoute
                exact
                path="/vendors/create-vendors"
                component={Create_vendors}
              />
              <AdminRoute
                exact
                path="/products/list_product"
                component={List_product}
              />
              <AdminRoute
                exact
                path="/vendors/productList"
                component={List_product}
              />
              <AdminRoute
                exact
                path="/products/create-product"
                component={Create_product}
              />
              <AdminRoute
                exact
                path="/localization/transactions"
                component={Translations}
              />
              <AdminRoute
                exact
                path="/localization/currency-rates"
                component={Rates}
              />
              {newRole &&
              newRole?.some((r) =>
                [
                  "suUsers",
                  "superAdmin",
                  "readWithdraws",
                  "writeWithdraws",
                  "delWithdraws",
                ].includes(r)
              ) ? (
                <AdminRoute
                  exact
                  path="/funds-management"
                  component={FundsManagement}
                />
              ) : (
                <AdminRoute
                  exact
                  path="/funds-management"
                  component={Dashboard}
                />
              )}
              {newRole &&
              newRole?.some((r) =>
                [
                  "suUsers",
                  "superAdmin",
                  "readOrders",
                  "writeOrders",
                  "delOrders",
                ].includes(r)
              ) ? (
                <AdminRoute
                  exact
                  path="/order-management"
                  component={InventoryManagement}
                />
              ) : (
                <Route path="/order-management" component={Dashboard} />
              )}
              {newRole &&
              newRole?.some((r) => ["suUsers", "superAdmin"].includes(r)) ? (
                <AdminRoute
                  exact
                  path="/team-management"
                  component={TeamManagement}
                />
              ) : (
                <AdminRoute
                  exact
                  path="/team-management"
                  component={Dashboard}
                />
              )}
              {newRole &&
              newRole?.some((r) => ["suUsers", "superAdmin"].includes(r)) ? (
                <AdminRoute exact path="/addTeam" component={AddTeam} />
              ) : (
                <AdminRoute exact path="/addTeam" component={Dashboard} />
              )}
              {newRole &&
              newRole?.some((r) => ["suUsers", "superAdmin"].includes(r)) ? (
                <AdminRoute exact path="/updateTeam" component={UpdateTeam} />
              ) : (
                <AdminRoute exact path="/updateTeam" component={Dashboard} />
              )}
              <AdminRoute exact path="/localization/taxes" component={Taxes} />
              <AdminRoute exact path="/reports/report" component={Reports} />
              {/* {newRole &&
								newRole?.some((r) =>
									[
										'suUsers',
										'superAdmin',
										'readSetting',
										'writeSetting',
										'delSetting',
									].includes(r)
								) && */}{" "}
              <AdminRoute
                exact
                path="/settings"
                component={Profile}
                // pathName={window.location.pathname}
                // newRole={newRole}
              />
              {/* } */}
              <AdminRoute exact path="/invoice" component={Invoice} />
              <AdminRoute exact path="/data-table" component={Datatable} />
              {newRole &&
              newRole?.some((r) =>
                [
                  "suUsers",
                  "superAdmin",
                  "readProfile",
                  "writeProfile",
                  "delProfile",
                ].includes(r)
              ) ? (
                <AdminRoute
                  exact
                  path="/user-profile-details"
                  component={UserProfileDetails}
                />
              ) : (
                <AdminRoute
                  exact
                  path="/user-profile-details"
                  component={Dashboard}
                />
              )}
              {newRole &&
              newRole?.some((r) =>
                [
                  "suUsers",
                  "superAdmin",
                  "readRarityLevel",
                  "writeRarityLevel",
                  "delRarityLevel",
                ].includes(r)
              ) ? (
                <AdminRoute
                  exact
                  path="/products/add-raritylevel"
                  component={AddRaritylavel}
                />
              ) : (
                <AdminRoute
                  exact
                  path="/products/add-raritylevel"
                  component={Dashboard}
                />
              )}
              {newRole &&
              newRole?.some((r) =>
                [
                  "suUsers",
                  "superAdmin",
                  "readRarityLevel",
                  "writeRarityLevel",
                  "delRarityLevel",
                ].includes(r)
              ) ? (
                <AdminRoute
                  exact
                  path="/products/rarity-list"
                  component={Productrarity}
                />
              ) : (
                <AdminRoute
                  exact
                  path="/products/rarity-list"
                  component={Dashboard}
                />
              )}
              {newRole &&
              newRole?.some((r) =>
                [
                  "suUsers",
                  "superAdmin",
                  "readBotSimulator",
                  "writeBotSimulator",
                  "delBotSimulator",
                ].includes(r)
              ) ? (
                <AdminRoute
                  exact
                  path="/bot-simulator"
                  component={BotPlayList}
                />
              ) : (
                <AdminRoute exact path="/bot-simulator" component={Dashboard} />
              )}
              {newRole &&
              newRole?.some((r) =>
                [
                  "suUsers",
                  "superAdmin",
                  "readBotSimulator",
                  "writeBotSimulator",
                  "delBotSimulator",
                ].includes(r)
              ) ? (
                <AdminRoute exact path="/play-bot" component={PlayBox} />
              ) : (
                <AdminRoute exact path="/play-bot" component={Dashboard} />
              )}
              {newRole &&
              newRole?.some((r) =>
                [
                  "suUsers",
                  "superAdmin",
                  "readActivityLog",
                  "writeActivityLog",
                  "delActivityLog",
                ].includes(r)
              ) ? (
                <AdminRoute
                  exact
                  path="/activityLog"
                  component={ActivityLogList}
                />
              ) : (
                <AdminRoute exact path="/activityLog" component={Dashboard} />
              )}
              <AdminRoute exact path="/orderDetails" component={OrderDetail} />
              {newRole &&
              newRole?.some((r) =>
                [
                  "suUsers",
                  "superAdmin",
                  "readDelivery",
                  "writeDelivery",
                  "delDelivery",
                ].includes(r)
              ) ? (
                <AdminRoute
                  exact
                  path="/shippingManagement"
                  component={ShippingManagement}
                />
              ) : (
                <AdminRoute
                  exact
                  path="/shippingManagement"
                  component={Dashboard}
                />
              )}
              {newRole &&
              newRole?.some((r) =>
                [
                  "suUsers",
                  "superAdmin",
                  "readDelivery",
                  "writeDelivery",
                  "delDelivery",
                ].includes(r)
              ) ? (
                <AdminRoute
                  exact
                  path="/rackBackSettings"
                  component={RackBackSettings}
                />
              ) : (
                <AdminRoute
                  exact
                  path="/rackBackSettings"
                  component={Dashboard}
                />
              )}
              <AdminRoute
                exact
                path="/deposite-mangement"
                component={DepositeManagement}
              />
              <AdminRoute exact path="/raindrop-data" component={RainDrop} />
              <AdminRoute exact path="/deposite-details" component={Bill} />
              <AdminRoute
                exact
                path="/faq-category"
                component={FaqManagement}
              />
              <AdminRoute
                exact
                path="/faq-addcategory"
                component={AddCategoryfaq}
              />
              <AdminRoute exact path="/faq" component={FaqManagementData} />
              <AdminRoute exact path="/addfaq" component={Addfaq} />
              <AdminRoute
                exact
                path="/affiliate-level"
                component={AffiliateLevelManagement}
              />
              <AdminRoute
                exact
                path="/addAffiliateLevel"
                component={AddAffiliateLevel}
              />
              <AdminRoute
                exact
                path="/affiliate-users"
                component={AffiliateUserManagement}
              />
              <AdminRoute
                exact
                path="/affiliate-stats"
                component={AffiliateStats}
              />
              {/* Allwoed country component route  */}
              <AdminRoute
                exact
                path="/country-allow"
                component={AllowedCountry}
              />
            </App>
          </Switch>
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </PerfectScrollbar>
      </BrowserRouter>
    </Provider>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
